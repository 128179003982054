import { ContainerBorderRadius } from 'components/containerBorderRadius';
import LoadingLobby from 'components/lobby/loadingLobby';
import Navbar from 'components/navbar';
import Grid from 'components/uiKit/grid';
import Container from 'components/uiKit/grid/container';
import { Suspense, lazy } from 'react';
const Lobby = lazy(() => import('components/lobby'));
export default function HomeDesktopLayout() {
  return <ContainerBorderRadius bg="var(--navbar-bg)" data-sentry-element="ContainerBorderRadius" data-sentry-component="HomeDesktopLayout" data-sentry-source-file="index.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Grid padding={['pb-5']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Suspense fallback={<LoadingLobby />} data-sentry-element="Suspense" data-sentry-source-file="index.tsx">
            <Lobby lobbyName="kasino" data-sentry-element="Lobby" data-sentry-source-file="index.tsx" />
          </Suspense>
        </Grid>
      </Container>
    </ContainerBorderRadius>;
}